<template>
  <div v-if="banners != null && banners.length > 0" class="multi-banner-wrap">
    <div class="banner-layer">
      <carousel ref="theatreCarousel" :items-to-show="1" :wrapAround="true" :transition="500">
        <slide v-for="banner in banners" :key="banner.id">
          <banner-skeleton v-if="isLoading"/>
          <div class="banner-bg" v-if="!isLoading">
<!--            <div class="banner-content-layer" :style="{background: banner.color ? banner.color : 'black'}"></div>-->

            <div class="banner-media">
              <div class="banner-media-gradient"
                   :style="{background: `linear-gradient(to right,${banner.color ? banner.color: 'black'},transparent,transparent)`}"></div>
              <div
                  v-if="banner.type == '1'"
                  :style="`background: url(https://btvplus.mn/${encodeURI(banner.image)})`"
                  class="banner-image"
              />

              <video-background
                  :loop="true"
                  v-if="banner.type == '2'"
                  :src="banner.video"
                  alt="banner video"
                  class="banner-video"
              />
            </div>

            <div class="container">
              <div class="banner-content space-y-6">
<!--                <div class="rnd">Товхимол</div>-->
                <Image
                    v-if="banner.logo != null"
                    :src="banner.logo"
                    alt="banner"
                    class="banner-logo"
                />
                <h1 v-else class="text-3xl font-bold line-clamp-2">
                  {{ banner.title }}
                </h1>

                <div class="tag-wrap">
                  <span class="tag"><b>Улс төр</b></span>
                  <span class="tag"><b>Эдийн засаг</b></span>
                  <span class="tag"><b>Эрүүл мэнд</b></span>
                </div>

                <p class="text-lg line-clamp-4 font-medium banner-desc"
                   :style="{color: banner.desc_color?banner.desc_color: 'white'}" v-html="banner.title"></p>

                <div class="flex items-center space-x-2">
                  <a class="p-button text-white  play-btn inline-flex banner-txt" :href="banner.url" v-if="banner?.url"
                     @click="this.$router.push(banner.url)"
                     :style="{background: banner.button_color?banner.button_color: '#003CAA'}">
                    <i class="pi pi-info-circle"
                       :style="{color: banner.button_text_color?banner.button_text_color: 'white', fontSize: '20px'}"></i>
                    <p class="text-bold" :style="{color: banner.button_text_color?banner.button_text_color: 'white'}">
                      Дэлгэрэнгүй</p></a>
                </div>
              </div>
            </div>
          </div>
        </slide>

        <template #addons>
          <pagination/>
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import {Carousel, Slide, Pagination} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import useDevice from "@hooks/useDevice";
import Image from "./Image.vue";
import BannerSkeleton from "@skeletons/BannerSkeleton.vue";
import VideoBackground from 'vue-responsive-video-background-player'

export default {
  components: {Image, BannerSkeleton, VideoBackground, Carousel, Slide, Pagination},
  props: ["action", "getter"],
  setup() {
    const {isDesktop} = useDevice();
    return {isDesktop};
  },
  computed: {
    banners() {
      return this.$store.state[this.getter];
    }
  },

  data() {
    return {
      isLoading: false
    }
  },

  created() {
    this.$store.dispatch(this.action, {vm: this, multi: true})
  }
};
</script>